import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class StructuredCabling extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Cablare structurata</h2>
                    <p className='security__top-info'>Cablarea structurata reprezinta, practic, instalarea cablurilor, a conectorilor, a panourilor de programare si a altor componente prin care se face transferul fizic de date si/sau voce intr-o retea interna sau externa. Cablareas tructurata presupune o singura infrastructura care poate sustine telefoane, calculatoare (PC-uri) si alte echipamente de comunicatii si permite conectarea rapida si flexibila a echipamentelor utilizate</p>
                    <p className='security__info'>Desi poate parea simplu la prima vedere, realizarea cablajului structurat este un procedeu complex, iar viteza cu care se dezvolta tehnologiile de comunicatie si latimea de banda tot mai mare complica si mai mult situatia. Noi venim cu solutii complete ce cuprind documentatia, proiectarea, implementarea, intretinerea si upgrade-urile necesare realizarii unui sistem de cablare structurata.</p>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Avantaje:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>eficienta si rezistenta in timp;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>viteza mare a traficului de date;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>costuri de administrare reduse;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>ulterior realizarii retelei structurate pot fi conectate echipamente noi fara a fi necesara intreruperea functionarii sistemului.</li>
                                </div>
                            </ul>
                        </div>
                        <img src='/images/cable.jpg' className='standard__only-desktop security__section-img' alt='cable'/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default StructuredCabling;