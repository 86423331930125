import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Certifications extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Certificări</h2>
                            <p className='security__top-info'>S.C. COVO SOLUTIONS S.R.L. este autorizată de către Inspectoratul General al Politiei Române Direcția Poliției de Ordine Publică, cu licența nr.  4616/T 06.06.2018 în conformitate cu  art. 34 din Legea nr. 333/2003 privind paza obiectivelor, bunurilor, valorilor și protecția persoanelor să :</p>
                            <ul className='security__list security__list-types'>
                                    <li className='security__list-type'>proiecteze sisteme de securitate;</li>
                                    <li className='security__list-type'>instaleze sisteme de securitate;</li>
                                    <li className='security__list-type'>întrețină sisteme de securitate;</li>
                                    <li className='security__list-type'>realizeze analize de risc la securitate fizică pentru instituții și agenți economici.</li>
                                </ul>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Certifications;