import React from "react";
import './OptimizedImg.scss';

const _loaded = {};

class ImageLoader extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      srcImage: ''
    };

    this.setImageProperSrc();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setImageProperSrc();
    }
  }

  setImageProperSrc = () => {
    window.Modernizr.on('webp', result => {
      if (result) {
        this.setState({ srcImage: this.props.imageUrlWebp });
      } else {
        this.setState({ srcImage: this.props.imageUrl });
      }
    });
  };

  //define our loading and loaded image classes
  static defaultProps = {
    className: "",
    loadingClassName: "img-loading",
    loadedClassName: "img-loaded"
  };

  //image onLoad handler to update state to loaded
  onLoad = () => {
    _loaded[this.state.srcImage] = true;
    this.setState(() => ({ loaded: true }));
  };


  render() {

    let { className, loadedClassName, loadingClassName } = this.props;

    className = `${className} ${this.state.loaded
      ? loadedClassName
      : loadingClassName}`;

    return <img
             src={this.state.srcImage}
             alt={this.props.alt}
             onClick={this.props.onClick}
             className={className}
             onLoad={this.onLoad} />;
  }
}

export default ImageLoader;
