import React from 'react';
import './Homepage.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {Link} from 'react-router-dom';
import LazyLoad from 'react-lazy-load';
import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';

class Homepage extends React.Component{
    scrollToTop=()=>{
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div className='standard homepage'>
                <Header/>
                <div className='homepage__first-section'>
                  <LazyLoad
                    debounce={false}
                    offsetVertical={500}
                  >
                    <OptimizedImg src='/images/covo-banner.jpg' imageUrlWebp='/images/covo-banner.webp' className='homepage__first-img' alt='houses' />
                  </LazyLoad>
                    <div className='homepage__title-box'>
                        <div className='homepage__title-main'><h1 className='homepage__title'>COVO SOLUTIONS</h1></div>
                        <h1 className='homepage__motto'>THINGS GET BETTER WITH US</h1>
                    </div>
                </div>
                <div className='homepage__about-section'>
                    <h3 className='homepage__about-title'>Scurta descriere COVO SOLUTIONS</h3>
                    <h4 className='homepage__about-info'>COVO SOLUTIONS este  înființată în anul 2015, și activează ca integrator de soluții în securitate și  automatică.  Experiența acumulată de-a lungul timpului se bazează pe inovație, ingeniozitate, calitatea execuției, iar acest lucru ne aduce un avantaj cert în fata competitorilor. Altfel spus, COVO SOLUTIONS este soluția optimă atunci când se doreste seriozitate și proiecte corect integrate!</h4>
                </div>
                <div className='homepage__security-section'>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      <OptimizedImg src='/images/covo-2.jpg' imageUrlWebp='/images/covo-2.webp' className='homepage__section-img' alt='security' />
                    </LazyLoad>
                    <div className='homepage__security-details'>
                        <h3 className='homepage__section-title'>Protejează-te pe tine și ai tăi</h3>
                        <h4 className='homepage__section-info'>Solutiile de supraveghere video joaca un rol important in lupta impotriva activitatilor ilegale si contribuie si la cresterea operativitatii tuturor sistemelor de securitate instalate in obiectivele cu cerinte minimale de securitate.</h4>
                        <h4 className='homepage__section-info'>COVO SOLUTIONS este soluția optimă atunci când se doreste seriozitate și proiecte corect integrate.</h4>
                        <div className='homepage__services-box'>
                            <Link className='standard__link homepage__link' onClick={this.scrollToTop} to='/sisteme-de-securitate'>
                            <div className='homepage__individual-service'>
                                <LazyLoad
                                  debounce={false}
                                  offsetVertical={500}
                                >
                                  <OptimizedImg src='/images/security-camera.jpg' imageUrlWebp='/images/security-camera.webp' className='homepage__service-img' alt='service' />
                                </LazyLoad>
                                <h4 className='homepage__service-name'>Sisteme de supraveghere video</h4>
                            </div>
                            </Link>
                            <Link className='standard__link homepage__link' onClick={this.scrollToTop} to='/sisteme-de-alarma'>
                            <div className='homepage__individual-service'>
                                <LazyLoad
                                  debounce={false}
                                  offsetVertical={500}
                                >
                                  <OptimizedImg src='/images/alarm.jpg' imageUrlWebp='/images/alarm.webp' className='homepage__service-img' alt='alarm' />
                                </LazyLoad>
                                <h4 className='homepage__service-name'>Sisteme de alarma</h4>
                            </div>
                            </Link>
                            <Link className='standard__link homepage__link'  onClick={this.scrollToTop} to='/sisteme-interfonie'>
                            <div className='homepage__individual-service'>
                                <LazyLoad
                                  debounce={false}
                                  offsetVertical={500}
                                >
                                  <OptimizedImg src='/images/interfon.jpg' imageUrlWebp='/images/interfon.webp' className='homepage__service-img' alt='interfon' />
                                </LazyLoad>
                                <h4 className='homepage__service-name'>Sisteme de interfonie</h4>
                            </div>
                            </Link>
                            <Link className='standard__link homepage__link' onClick={this.scrollToTop} to='/sisteme-control-acces'>
                            <div className='homepage__individual-service'>
                                <LazyLoad
                                  debounce={false}
                                  offsetVertical={500}
                                >
                                  <OptimizedImg src='/images/access-card.png' imageUrlWebp='/images/access-card.webp' className='homepage__service-img' alt='access card' />
                                </LazyLoad>
                                <h4 className='homepage__service-name'>Sisteme de control acces</h4>
                            </div>
                            </Link>
                        </div>
                    </div>

                    <div className='homepage__home-section'>
                        <LazyLoad
                          debounce={false}
                          offsetVertical={500}
                        >
                          <OptimizedImg src='/images/covo-3.jpg' imageUrlWebp='/images/covo-3.webp' className='homepage__home-img' alt='home' />
                        </LazyLoad>
                        <div className='homepage__fire-right-section'>
                            <h3 className='homepage__section-title'>Siguranta pentru casa ta</h3>
                            <h4 className='homepage__section-info homepage__fire-details'>Covo Solutions va ofera sisteme inovatoare care sa va protejeze impotriva intrarii prin efractie si a furtului. in plus, sistemele noastre sunt concepute astfel incat sa fie usor integrate in alte siteme de securitate.</h4>
                            <div className='homepage__fire-box'>
                            <Link className='standard__link homepage__card-link' onClick={this.scrollToTop} to='/automatizari-cai-acces'>
                                <div className='homepage__card'>
                                    <LazyLoad
                                      debounce={false}
                                      offsetVertical={500}
                                    >
                                      <OptimizedImg src='/images/gates.jpg' imageUrlWebp='/images/gates.webp' className='homepage__fire-img' alt='smoke detector' />
                                    </LazyLoad>
                                    <h4 className='homepage__card-info'>Automatizari cai de acces</h4>
                                </div>
                            </Link>
                                <Link className='standard__link homepage__card-link' onClick={this.scrollToTop} to='/retele-voce-date'>
                                <div className='homepage__card'>
                                    <LazyLoad
                                      debounce={false}
                                      offsetVertical={500}
                                    >
                                      <OptimizedImg src='/images/webs.jpg' imageUrlWebp='/images/webs.webp' className='homepage__fire-img' alt='fire' />
                                    </LazyLoad>
                                    <h4 className='homepage__card-info'>Retele voce-date</h4>
                                </div>
                                </Link>
                                <Link className='standard__link homepage__card-link' onClick={this.scrollToTop} to='/servicii-instalatii-electrice'>
                                <div className='homepage__card'>
                                    <LazyLoad
                                      debounce={false}
                                      offsetVertical={500}
                                    >
                                      <OptimizedImg src='/images/electricity.jpg' imageUrlWebp='/images/electricity.webp' className='homepage__fire-img' alt='electricity' />
                                    </LazyLoad>
                                    <h4 className='homepage__card-info'>Servicii de instalații electrice</h4>
                                </div>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <Link to='/servicii-it' className='standard__link'><h3 className='homepage__section-title homepage__subtitle'>Servicii IT</h3></Link>
                    <h4 className='homepage__section-info homepage__subtitle homepage__subtitle-info'>Oferim o gamă diversă de servicii IT și mentenanță IT pentru afacerea dumneavoastră.</h4>
                    <div className='homepage__it-section'>
                        <h4 className='homepage__it-services'>Mentenanță calculatoare/laptopuri</h4>
                        <h4 className='homepage__it-services'>Mentenanță echipamente de printare</h4>
                        <h4 className='homepage__it-services'>Inchirieri ecipamente de printare si sisteme de securitate</h4>
                        <h4 className='homepage__it-services'>Consumabile pentru echipamente de printare</h4>
                        <h4 className='homepage__it-services'>Încărcări cartușe</h4>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Homepage;
