import React from 'react';
import './Contact.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {contactService} from '../../services/ContactService';
import NotificationSystem from 'react-notification-system';
import NotificationSystemStyle from '../../constants/NotificationSystemStyle';

class Contact extends React.Component{
    constructor(props){
        super(props)
        this.state={
            name:"",
            email:"",
            phoneNumber:"",
            message:""
        }
        this.notificationSystem = React.createRef();
    }
    componentDidMount() {
        this._notificationSystem = this.refs.notificationSystem;
    }
    handleChange=event=>{
        this.setState({
            [event.target.name]:event.target.value
        })
    }
    handleSubmit=event=>{
        event.preventDefault();
        const notification = this.notificationSystem.current;
        contactService.saveContact(this.state).then(response=>{
            notification.addNotification({
                message: 'Mesajul a fost trimis cu success.',
                level: 'success',
                position: 'tc'
              });
        })
        .catch(err=>{
            notification.addNotification({
                message:
                  'A aparut o problema, faceti refresh la pagina sau contactati administratorul daca problema persista.',
                level: 'error',
                position: 'tc'
            });
        })
    }
    render(){
        return(
            <div className='standard contact'>
                <Header/>
                <div className='contact__wrapper'>
                    <h2 className='security__title'>Contact</h2>
                    <div className='contact__container'>
                    <div className='contact__left-section'>
                    <h3 className='contact__subtitle'>Trimite-ne un mesaj</h3>
                    <form className='contact__form'onSubmit={this.handleSubmit}>
                        <h4 className='contact__label'>Nume si prenume:</h4>
                        <input
                                className='contact__input'
                                type="text"
                                name="name"
                                onChange={this.handleChange}
                                required
                                value={this.state.name}/>
                        <h4 className='contact__label'>Adresa de email:</h4>
                        <input
                                className='contact__input'
                                type="text"
                                name="email"
                                required
                                value={this.state.email}
                                onChange={this.handleChange}/>
                        <h4 className='contact__label'>Numar de telefon:</h4>
                                <input
                                className='contact__input'
                                type="number"
                                name="phoneNumber"
                                onChange={this.handleChange}
                                required
                                value={this.state.phoneNumber}/>

                        <h4 className='contact__label'>Mesajul tau:</h4>
                            <textarea cols="30"
                            rows="2"
                            required
                            type='text'
                            className='contact__text-area contact__input'
                            name="message"
                            value={this.state.message}
                            onChange={this.handleChange}
                            />
                            <button type='submit' className='contact__submit-button'><h5 className='contact__button-writing'>TRIMITE EMAIL</h5></button>
                    </form>
                    </div>
                    <div className='contact__info-section'>
                        <h3 className='contact__subtitle'>Informaţii contact</h3>
                        <div className='contact__info'>
                            <h4 className='contact__specific-info'>Pentru suport tehnic, va rugam sa ne contactati la urmatoarele numere de telefon/e-mail:</h4>
                            <div className='contact__info-box'>
                                <img src='/images/mail.svg' className='contact__icon' alt='mail'/>
                                <h4 className='contact__detail'>tehnic@covosolutions.ro</h4>
                            </div>
                            <div className='contact__info-box'>
                                <img src='/images/call.svg' className='contact__icon' alt='phone'/>
                                <h4 className='contact__detail'>0755 686 871</h4>
                            </div>
                            <h4 className='contact__specific-info'>Pentru vanzari, va rugam sa ne contactati la urmatoarele numere de telefon/e-mail:</h4>
                            <div className='contact__info-box'>
                                <img src='/images/mail.svg' className='contact__icon' alt='mail'/>
                                <h4 className='contact__detail'>contact@covosolutions.ro</h4>
                            </div>
                            <div className='contact__info-box'>
                                <img src='/images/call.svg' className='contact__icon' alt='phone'/>
                                <h4 className='contact__detail'>0755 686 871</h4>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
                <Footer/>
                <NotificationSystem
                  ref={this.notificationSystem}
                  style={NotificationSystemStyle}
                />
            </div>
        )
    }
}

export default Contact;
