import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class RentingCopyMachines extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Închirieri copiatoare</h2>
                    <p className='security__top-info'>Inchiriere copiatoarelor oferita reprezinta un mix de echipamente si servicii, optim pentru desfasurarea activitatii dumneavoastra. Inchirierea echipamentelor reprezinta contractarea unui serviciu si nu achizitia unuiprodus.  <span className='security__bold'>Nu trebuie sa va faceti niciodata griji cu garantia acestora!</span></p>
                    <p className='security__info '>Solutia de inchiriere inseamna ca nu mai aveti costuri suplimentare ascunse! </p>
                    <div className='security__perks-box'>
                        <p className='security__info security__renting-perks'>Consumabilele sunt INCLUSE! </p>
                        <img src='/images/correct.svg' alt='check' className='security__check-icon'/>
                    </div>
                    <div className='security__perks-box'>
                    <p className='security__info security__renting-perks'>Service-ul este INCLUS! </p>
                    <img src='/images/correct.svg' alt='check' className='security__check-icon'/>
                    </div>
                    <div className='security__perks-box'>
                    <p className='security__info security__renting-perks'>Transportul este INCLUS! </p>
                    <img src='/images/correct.svg' alt='check' className='security__check-icon'/>
                    </div>
                    <div className='security__perks-box'>
                    <p className='security__info security__renting-perks'>Instalarea este INCLUSA! </p>
                    <img src='/images/correct.svg' alt='check' className='security__check-icon'/>
                    </div>
                    <div className='security__perks-box'>
                    <p className='security__info security__renting-perks'>Instruirea personalului este INCLUSA!</p>
                    <img src='/images/correct.svg' alt='check' className='security__check-icon'/>
                    </div>
                            <h3 className='security__subtitle'>Pasii urmati pentru inchirierea unui echipament:</h3>
                            <div className='security__renting-steps'>
                                <div className='security__renting-steps-box'>
                                    <img src='/images/steps.jpg' alt='step' className='security__renting-step-icon'/>
                                    <h3 className='security__step'>Va ajutam sa alegeti echipamentul potrivit</h3>
                                </div>
                                <div className='security__renting-steps-box'>
                                    <img src='/images/help.jpg' alt='step' className='security__renting-step-icon'/>
                                    <h3 className='security__step'>Gasim impreuna solutia de inchiriere potrivita</h3>
                                </div>
                                <div className='security__renting-steps-box'>
                                    <img src='/images/installation.jpg' alt='step' className='security__renting-step-icon'/>
                                    <h3 className='security__step'>Transportam si instalam echipamentul</h3>
                                </div>
                                <div className='security__renting-steps-box'>
                                    <img src='/images/bulb.jpg' alt='step' className='security__renting-step-icon'/>
                                    <h3 className='security__step'>Va instruim cum sa folositi optim echipamentul</h3>
                                </div>
                            </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default RentingCopyMachines;