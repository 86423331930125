import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class RiscAnalysis extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Analiză de risc</h2>
                            <p className='security__top-info'>Analiza de risc la securitatea fizică este faza incipientă instituirii măsurilor de securitate și are rolul de a evidenția gradul de vulnerabilitate pe care o instituție/agent economic îl are în fața unei posibile tentative de efracție.</p>
                            <p className='security__info'>Mai exact, cunoscut fiind faptul că gradul de infracționalitate la nivel național are valori diferite în funcție de județ/localitate, un agent economic care are o rețea de magazine la nivel național, cu un anumit obiect de activitate fix, va avea un risc diferit la punctele de lucru.</p>
                            <p className='security__info'>In acest sens EVALUATORUL DE RISC LA SECURITATE FIZICĂ, în funcție de indicii zonali, va putea concluziona faptul că agentul economic respectiv la unele sucursale aflate în zone cu grad redus de infracționalitate își va putea desfășura activitatea doar cu un sistem de alarmă, iar la alte unități asemănătoare, dar în zone geografice diferite, va avea nevoie suplimentar de sistem de supraveghere video sau chiar agent de pază pentru a se putea încadra astfel într-un nivel de risc minim acceptat.</p>
                            <p className='security__info'>Conform art. 2 alin (1) din Legea 333/2003 – “Ministerele şi celelalte organe de specialitate ale administraţiei publice centrale şi locale, regiile autonome, companiile şi societăţile naţionale, institutele naţionale de cercetare-dezvoltare, societăţile comerciale, indiferent de natura capitalului social, precum şi alte organizaţii care deţin bunuri ori valori cu orice titlu, denumite în prezent alegeunităţi, sunt obligate să asigure paza acestora.”</p>
                            <p className='security__info'>Conducătorii unităţilor deţinătoare de bunuri şi valori au obligaţia de a identifica şi stabili zonele funcţionale, corespunzător activităţii desfăşurate, şi de a adopta măsurile necesare asigurării protecţiei vieţii, integrităţii persoanelor şi siguranţei valorilor. Aceste servicii sunt prestate de catre un <span className='security__bold'> evaluator de risc</span>.</p>
                            <p className='security__info'>Această evaluare este necesară atunci cand doriți să depuneti spre avizare planul de pază al unității și vă evidențiază exact măsurile de securitate ce trebuiesc luate.</p>
                        <div className='security__lower-section'>
                        <div>
                        <h3 className='security__subtitle'>   Analiza de risc se efectuează înaintea instituirii măsurilor profesionale de securitate și se revizuiește în una dintre următoarele situații:</h3>
                                <ul className='security__list security__list-types'>
                                    <li className='security__list-type'>Cel puțin o dată la 3 ani, pentru corelarea cu dinamica parametrilor interni și externi care generează  și/sau modificăriscurile la securitateafizică a unității;</li>
                                    <li className='security__list-type'>În cel mult 60 de zile de la producerea unui incident de securitate la unitatea respectivă;</li>
                                    <li className='security__list-type'>În cel mult 30 de zile de la modificarea caracteristicilor arhitecturale, funcţionale sau a obiectului de activitate al unității.</li>
                                </ul>
                        <p className='security__info'>O simplă analiză de risc la securitatea fizică reprezintă baza adoptării măsurilor de securitate a obiectivelor, bunurilor și valorilor prevăzute de legea în vigoare, puse în planul de protecție și proiectul sistemului de alarmare.</p>
                        <p className='security__info'>Evaluarea profesională a riscurilor este o abordare logică și ordonată, reprezentând o bună soluție de identificare a problemelor de securitate, prins tabilirea probabilității de producere a riscurilor.</p>
                        <p className='security__info'>Toate societățiile comerciale sunt obligate sa contacteze un evaluator care sa realizeze analiza de risc la securitate fizica, aceasta urmand a fi efectuata pentru toate si fiecare dintre obiectele de activitate active si sediile pe care firmele le au conform legii.</p>
                        <p className='security__info'>Firmele infiintate dupa 2012 trebuie sa aiba implementata Analiza de risc.</p>
                        <p className='security__info'>Incalcarea prevederilor din legislatia specifica privind elaborarea si implementarea analizei de risc la securitatea fizica constituie contraventie si se sanctioneaza cu amenda de la 5.000 la 10.000 RON.</p>
                        </div>
                        <img src='/images/analiza.jpg' className='standard__only-desktop security__analysis-img' alt='security'/>
                        </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default RiscAnalysis;