import React from 'react';
import { Link } from 'react-router-dom';
import '../../resources/main.scss';
import './Menu.scss';
import menuJson from './menu.json';
import SubMenu from './SubMenu';

class Menu extends React.Component{
    constructor(props){
        super()
        this.state={
            menuItems:menuJson,
            openMenuById:0
        }
    }
    enterButton = id => {
        this.setState({ openMenuById: id });
        document.body.classList.add('has-active-menu');
      };
    
      leaveButton = () => {
        this.setState({ openMenuById: 0 });
        document.body.classList.remove('has-active-menu');
      };
    render(){
        return(
            <div className='standard menu'>
             <ul className='menu__links'>
                {this.state.menuItems.map(menuItem=>(
                   <li key={menuItem.id} className='menu__list-item'>
                    {menuItem.submenu ? (
                        <div
                          onMouseEnter={() => this.enterButton(menuItem.id)}
                          onMouseLeave={this.leaveButton}
                          className='menu__submenu-box'
                        >
                         <Link className='standard__link' to={menuItem.url}> <span>{menuItem.item}</span></Link>
                        <img src='/images/arrow-down.svg' className={menuItem.submenu?'menu__arrow-icon':''} alt='arrow'/>
                        <SubMenu
                              menu={menuItem}
                              buttonHovered={this.state.openMenuById}
                              closeMenu={this.leaveButton}
                          />
                        </div>
                    ):( menuItem.externalLink === true ? <a className='standard__link menu__item' href={menuItem.url} target='_blank' rel="noopener noreferrer" ><span>{menuItem.item}</span></a> : <Link to={menuItem.url} className='standard__link menu__item'>{menuItem.item}</Link> 
                    )}
                    </li>
                ))}
                </ul>
            </div>
        )
    }
}

export default Menu;