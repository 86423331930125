import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import './App.scss';
import Homepage from '../src/containers/Homepage/Homepage';
import SecuritySystems from '../src/containers/SecuritySystems/SecuritySystems';
import Contact from '../src/containers/Contact/Contact';
import AlarmSystems from '../src/containers/AlarmSystems/AlarmSystems';
import AccesControl from '../src/containers/AccesControl/AccesControl';
import RiskAnalysis from  '../src/containers/RiskAnalysis/RiskAnalysis';
import Legislation from '../src/containers/Legislation/Legislation';
import ITMentenance from '../src/containers/ITMentenance/ITMentenance';
import StructuredCabling from '../src/containers/StructuredCabling/StructuredCabling';
import RentingCopyMachines from '../src/containers/RentingCopyMachines/RentingCopyMachines';
import AccesAutomatization from '../src/containers/AccesAutomatization/AccesAutomatization';
import InterphoneSystems from '../src/containers/InterphoneSystems/InterphoneSystems';
import Blog from '../src/containers/Blog/Blog';
import Article from '../src/containers/Article/Article';
import Certifications from '../src/containers/Certifications/Certifications';
import ElectricInstalations from '../src/containers/ElectricInstalations/ElectricInstalations';
import ITServices from '../src/containers/ITServices/ITServices';
import VoiceDateWeb from '../src/containers/VoiceDateWeb/VoiceDateWeb';

class App extends React.Component{
  render(){
    return (
      <div>
          <Router>
            <Route exact path="/" component={Homepage}/>
            <Route exact path="/sisteme-de-securitate" component={SecuritySystems}/>
            <Route exact path="/sisteme-de-alarma" component={AlarmSystems}/>
            <Route exact path="/sisteme-control-acces" component={AccesControl}/>
            <Route exact path="/analiza-risc" component={RiskAnalysis}/>
            <Route exact path="/legislatie" component={Legislation}/>
            <Route exact path="/mentenanta-it" component={ITMentenance}/>
            <Route exact path="/cablare-structurata" component={StructuredCabling}/>
            <Route exact path="/inchiriere-echipamente" component={RentingCopyMachines}/>
            <Route exact path="/automatizari-cai-acces" component={AccesAutomatization}/>
            <Route exact path="/contact" component={Contact}/>
            <Route exact path="/sisteme-interfonie" component={InterphoneSystems}/>
            <Route exact path="/noutati" component={Blog}/>
            <Route exact path="/articol/:id" component={Article}/>
            <Route exact path="/certificari" component={Certifications}/>
            <Route exact path="/servicii-instalatii-electrice" component={ElectricInstalations}/>
            <Route exact path="/servicii-it" component={ITServices}/>
            <Route exact path="/retele-voce-date" component={VoiceDateWeb}/>
          </Router>
      </div>
    )
  }

}

export default App;
