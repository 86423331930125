import React from 'react';
import './Header.scss';
import '../../resources/main.scss';
import HamburgerMenu from './HamburgerMenu';
import Menu from './Menu.jsx';
import {Link} from 'react-router-dom';

class Header extends React.Component{
    render(){
        return(
            <div className='standard header'>
                <div className='header__box'>
                   <Link to='/' className='standard__link'> <img src='/images/logo.jpg' alt='logo' className='header__logo'/></Link>
                </div>
                <HamburgerMenu/>
                <Menu/>
            </div>
        )
    }
}

export default Header;