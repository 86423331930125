import React from 'react';
import '../../resources/main.scss';
import './HamburgerMenu.scss';
import { Link } from 'react-router-dom';

class HamburgerMenu extends React.Component{
    constructor(props){
        super(props)
        this.state={
            activeMenu: false,
            subMenu:false
        }
    }
    openMenu=() =>{
        this.setState({
            activeMenu:true       })
            document.body.classList.add('has-active-menu');
    };
    closeMenu=()=>{
        this.setState({
            activeMenu:false        })
            document.body.classList.remove('has-active-menu');
    }
    toggleSubmenu=()=>{
        console.log('fsd')
        let subMenu=this.state.subMenu;
        subMenu=!subMenu;
        this.setState({subMenu})
    }
    render(){
        return(
            <div className="standard hamburger">
                <img
                onClick={this.openMenu}
                className="hamburger__icon"
                src='/images/menu.svg'
                alt="hamburgetMenuLogo"
                />
          <ul
            className={
              'hamburger__links' + (this.state.activeMenu ? ' active' : '')
            }
          >
            <h3 className=" hamburger__link--menu">Meniu</h3>
           <Link className='standard__link' to='/'> <li className=" hamburger__list-item">Acasă</li></Link>
           <div className='hamburger__link-box'onClick={()=>this.toggleSubmenu()}>
                <Link className='standard__link' to='/sisteme-de-securitate'><li className=" hamburger__list-item">Sisteme de securitate</li></Link>
                <img src={this.state.subMenu? '/images/arrow-up.png':'/images/arrow-down.png'}  alt='arrow'/>
           </div>
            {this.state.subMenu? 
            
            
            <ul className='hamburger__submenu'>
            <Link className='standard__link' to='/sisteme-de-securitate'> <li className=" hamburger__list-item">Sisteme de supraveghere video</li></Link>
            <Link className='standard__link' to='/sisteme-de-alarma'> <li className=" hamburger__list-item">Sisteme de alarmă</li></Link>
            <Link className='standard__link' to='/'> <li className=" hamburger__list-item">Detecție și semnalizare incendiu</li></Link>
            <Link className='standard__link' to='/'> <li className=" hamburger__list-item">Limitare și stingere incendiu</li></Link>
            <Link className='standard__link' to='/sisteme-control-acces'> <li className=" hamburger__list-item">Sisteme de control acces</li></Link>
            <Link className='standard__link' to='/sisteme-interfonie'> <li className=" hamburger__list-item">Sisteme de interfonie</li></Link>
            <Link className='standard__link' to='/automatizari-cai-acces'> <li className=" hamburger__list-item">Automatizări căi de acces</li></Link>
            <Link className='standard__link' to='/retele-voce-date'> <li className=" hamburger__list-item">Rețele voce-date</li></Link>
            <Link className='standard__link' to='/servicii-instalatii-electrice'> <li className=" hamburger__list-item">Servicii de instalații electrice</li></Link>
            <Link className='standard__link' to='/'> <li className=" hamburger__list-item">Sisteme antifurt</li></Link>
            </ul> :''} 
           <Link className='standard__link' to='/servicii-it'><li className=" hamburger__list-item">Servicii IT</li></Link>
           <Link className='standard__link' to='/mentenanta-it'><li className=" hamburger__list-item">Mentenanta IT</li></Link>
           <Link className='standard__link' to='/analiza-risc'><li className=" hamburger__list-item">Analiza de risc</li></Link>
           <Link className='standard__link' to='/certificari'><li className=" hamburger__list-item">Certificari</li></Link>
           <Link className='standard__link' to='/legislatie'><li className=" hamburger__list-item">Legislatie</li></Link>
           <a className='standard__link menu__item' href='https://kmw.ro/index.php?option=com_content&view=article&id=33' target='_blank' rel="noopener noreferrer" > <li className=" hamburger__list-item">Download</li></a>
            <Link className='standard__link' to='/contact'><li className=" hamburger__list-item">Contact</li></Link>

          </ul>
            <img
              onClick={this.closeMenu}
              className={
                'hamburger__close-icon' + (this.state.activeMenu ? ' active' : '')
              }
              src="/images/close.svg"
              alt="close Icon"
            />
        </div>
        )
    }
}

export default HamburgerMenu;
