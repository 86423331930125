import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class InterphoneSystems extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper security__flex-wrapper'>
                    <div>
                    <h2 className='security__title'>Sisteme de interfonie</h2>
                    <p className='security__top-info'>COVO SOLUTIONS este soluția ideală atunci cînd doriți să vă instalați un sistem de videointerfonie la reședința/biroul dumneavoastră.</p>
                    <p className='security__info'>Asigurăm servicii de instalare și întreținere a sistemelor de interfonie video și audio pentru zone rezidențiale și scări de bloc, atât în varianta cablată cât și radio.</p>
                    <h3 className='security__subtitle'>Soluțiile recomandate pentru scări de bloc</h3>
                    <p className='security__info'>    Pentru scările de bloc vă recomandăm o soluție foarte fiabilă marca RESEL sau ELECTRA, produsă în România și cu o durată medie de utilizare de peste 10 ani, în condițiile în care este asigurată mentenanța periodică.</p>
                    <h3 className='security__subtitle'>Avantajele superioare ale acestui sistem sunt:</h3>
                    <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Construcție robustă antivandal din metal;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Posibilitatea de a intra atât prin cartelă radio cât și prin cod propriu fiecărui apartament;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Comunicație FULL-DUPLEX între apartamente în mod gratuit;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Economie la curent pe casa scări, se aprinde lumina automat pentru câteva min. când intrați, respectiv când ieșiți din locuință;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Setați durata de aprindere a iluminatului;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Autonomie proprie la căderile de tensiune;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Puteți seta tipul de aclanșare a yalei;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Aveți posibilitatea de a deschide yala din apartament fără să fiți sunați;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Aveți posibilitatea de a deschide yala din apartament fără să fiți sunați.</li>
                                </div>
                            </ul>
                            <h3 className='security__subtitle'> Soluțiile recomandate pentru zone rezidențiale</h3>
                            <p className='security__info'>   Pentru zonele rezindețiale vă recomandăm sistemele de interfonie marca COMMAX, sisteme ce au o fiabilitate ridicată în timp, o funcționare ireproșabilă, un design și un aspect foarte plăcut.   </p>
                            <p className='security__info'> De asemena aveți posibilitatea să intrați pe bază de  acces din interior, cheie, cod, cartelă.  </p>
                            <h3 className='security__subtitle'> Automatizări căi de acces</h3>
                            <p className='security__info'> Oferim soluții de control pentru următoarele tipuri de căi de acces:</p>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'> Automatizări pentru porți batante;</li>
                                <li className='security__list-type'>Automatizări pentru porți culisante;</li>
                                <li className='security__list-type'>Controlul accesului pe drumuri private prin bariere automate;</li>
                                <li className='security__list-type'>Automatizare pentru usi din sticlă.</li>
                            </ul>
                            <h3 className='security__subtitle'>Principalele funcții pe care un astfel de sistem le permite sunt:</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>  Închiderea  porții: Automatizarea se poate regla pentru a detecta obstacolele și a-și schimba sensul de mișcare</li>
                                <li className='security__list-type'>Din telecomanda;</li>
                                <li className='security__list-type'>În mod automat, după un anumit timp;</li>
                                <li className='security__list-type'>În mod automat, după ce ați trecut prin fața fotocelulelor;</li>
                                <li className='security__list-type'>Folosind un modul dedicat, odată cu pornirea automatizării, puteți aprinde iluminatul exterior pentru o durată de timp prestabilită.</li>
                            </ul>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Avantajele instalării unui astfel de sistem, prin intermediul nostru, sunt următoarele:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Folosim numai mărci testate anterior, cu o rata de defect ce tinde spre zero;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Aveți certitudinea faptului că lucrarea este corect executată;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Garanție fermă 24 de luni, din partea noastră, la locația unde s-a executat lucrarea;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Intervenție rapidă la solicitarea beneficiarului, în cazul apariției unor anomalii în funcționare;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Service post-garanție și întreținere periodică;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Piese pentru service în maxim 48h lucrătoare;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Durata medie de funcționare pentru echipamentele furnizate de noi,  este de aproximativ 10 – 15 ani la trafic normal.</li>
                                </div>
                            </ul>
                        </div>

                    </div>
                    </div>
                    <img src='/images/interfon-covo.jpg' className='standard__only-desktop security__section-img security__alarm-img' alt='security'/>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default InterphoneSystems;
