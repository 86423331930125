import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LazyLoad from 'react-lazy-load';
import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';

class AccesAutomatization extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Automatizări căi de acces</h2>
                    <p className='security__top-info'>Sistemele de automatizari porti sau usi de garaj ofera un confort deosebit, totodata restrictioneaza accesul, in special cel al autovehiculelor.</p>
                    <p className='security__info'>La baza evolutiei sta o profunda dorinta de perfectionare continua, care a facut posibila dezvoltarea celor mai bune solutii pentru inchiderea spatiilor. Oferta de produse este imbunatatita cu servicii de montaj, consultanta tehnica si service specializat.</p>
                    <p className='security__info'>Colaboram cu producatori autorizati si suntem specializati in montarea sistemelor de automatizari porti si usi de garaj sectionale; oferind asistenta de specialitate in alegerea produselor de care aveti nevoie.</p>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Ce oferim:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>

                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Solutia potrivita, adaptata fiecarui client</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Calitatea echipamentelor, garantata</li>
                                </div>
                            </ul>

                        </div>
                        <LazyLoad
                          debounce={false}
                          offsetVertical={500}
                        >
                          <OptimizedImg src='/images/gates.jpg' imageUrlWebp='/images/gates.webp' className='standard__only-desktop security__section-img security__gate-img' alt='security' />
                        </LazyLoad>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default AccesAutomatization;
