import React from 'react';
import './Blog.scss';
import {blogService} from '../../services/BlogService';
import { Link } from 'react-router-dom';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import Moment from 'react-moment';


class Blog extends React.Component{
    constructor(props){
        super(props)
        this.state={
            articles:[],
            newestArticle: {
              main_image: {}
            }
        }
    }

    componentDidMount(){
        blogService.getArticles().then(response=>{
            let articles = this.state.articles;
            let newestArticle = { ...this.state.newestArticle }
            articles = response.data.articles;
            newestArticle = articles[articles.length - 1];

            this.setState({ articles });
            this.setState({ newestArticle });
        })
        .catch(err=>console.log(err))
    }
    render(){
        return(
            <div className='standard blog'>
            <div className='blog__header'>
            <Header/>
            </div>
                <div className='blog__container'>
                    <h2 className='blog__title'>Noutati</h2>
                    <h3 className='blog__subtitle'>COVO SOLUTIONS</h3>
                    { this.state.articles.length ? (
                      <div>
                      <Link to={`articol/${this.state.newestArticle.slug}`} className='blog__article-section blog__featured-article' >
                      { this.state.newestArticle.main_image && this.state.newestArticle.main_image.url ? (
                        <img src={process.env.REACT_APP_BLOG_API_URL + this.state.newestArticle.main_image.url} className='blog__main-img blog__featured-img' alt='car'/>
                      ) : (
                        <img src="/images/security-2.jpg" className='blog__main-img blog__featured-img' alt='security'/>
                      )}
                          <div className='blog__section-info blog__featured-section-info'>
                              <h3 className='blog__section-title blog__featured-section-title'>{this.state.newestArticle.title}</h3>
                              <h4 className='blog__section-details blog__featured-section-details'>{this.state.newestArticle.description}</h4>
                              <div className='blog__time-box'>
                                  <img src='/images/clock.png' className='blog__time-icon' alt='clock'/>
                                  <h5 className='blog__article-time'>
                                    <Moment format="DD/MM/YYYY">
                                        {this.state.newestArticle.updated_at}
                                    </Moment>
                                  </h5>
                              </div>
                          </div>
                      </Link>

                  <div className='blog__article-wrapper'>
                      {this.state.articles.slice(0, 3).map(article=>
                              <div key={article.id} className='blog__news-wrapper' >
                                  <Link to={`articol/${article.slug}`} className='blog__article-section' >
                                  { article.main_image && article.main_image.url ? (
                                    <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__main-img' alt='car'/>
                                  ) : (
                                    <img src="/images/car.jpg" className='blog__main-img' alt='car'/>
                                  )}
                                  <div className='blog__section-info'>
                                          <h3 className='blog__section-title'>{article.title}</h3>
                                          <h4 className='blog__section-details'>{article.description}</h4>
                                          <div className='blog__time-box'>
                                              <img src='/images/clock.png' className='blog__time-icon' alt='clock'/>
                                              <h5 className='blog__article-time'>
                                                <Moment format="DD/MM/YYYY">
                                                    {article.updated_at}
                                                </Moment>
                                              </h5>
                                          </div>
                                      </div>
                              </Link>
                              </div>
                          )}
                              {this.state.articles.slice(3).map(article=>
                              <div key={article.id} className='blog__news-wrapper'>
                                  <Link to={`article/${article.slug}`} className='blog__article-section' >
                                    { article.main_image && article.main_image.url ? (
                                      <img src={process.env.REACT_APP_BLOG_API_URL + article.main_image.url} className='blog__main-img' alt='car'/>
                                    ) : (
                                      <img src="/images/car.jpg" className='blog__main-img' alt='car'/>
                                    )}
                                    <div className='blog__section-info'>
                                          <h3 className='blog__section-title'>{article.title}</h3>
                                          <h4 className='blog__section-details'>{article.description}</h4>
                                          <div className='blog__time-box'>
                                              <img src='/images/clock.png' className='blog__time-icon' alt='clock'/>
                                              <h5 className='blog__article-time'>
                                                <Moment format="DD/MM/YYYY HH:mm">
                                                    {article.updated_at}
                                                </Moment>
                                              </h5>
                                          </div>
                                      </div>
                                      </Link>
                              </div>
                          )}

                  </div>
                      </div>
                    ) : (
                        <p>Nu sunt articole adaugate momentan.</p>
                    )}

                </div>
                <Footer/>
            </div>
        )
    }
}

export default Blog;
