import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class Legislation extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper security__law-container'>
                <div>
                    <h2 className='security__title'>Legislație</h2>
                    <div className='security__law-wrapper'>
                        <div className='security__law-box'>
                            <img src='/images/law.svg' alt='law' className='security__law-icon'/>
                            <h4
                                className="security__see-document"
                                onClick={() =>
                                window.open(
                                    '/documents/Instructiunea_nr_9.pdf',
                                    '_blank'
                                )}>
                            Instructiuni nr. 9 din 1 martie 2013 privind efectuarea analizelor de risc la securitatea fizica a unitatilor ce fac obiectul Legii nr. 333/2003 privind paza obiectivelor, bunurilor, valorilor si protectia persoanelor
                            </h4>
                        </div>
                        <div className='security__law-box'>
                            <img src='/images/law.svg' alt='law' className='security__law-icon'/>
                            <h4
                                className="security__see-document"
                                onClick={() =>
                                window.open(
                                    '/documents/hotarare_301.pdf',
                                    '_blank'
                                )}>
                           HG 301 din 11 aprilie 2012 pentru aprobarea Normelor metodologice de aplicare a Legii nr. 333/2003 privind paza obiectivelor, bunurilor, valorilor şi protecţia persoanelor

                            </h4>
                        </div>
                        <div className='security__law-box'>
                            <img src='/images/law.svg' alt='law' className='security__law-icon'/>
                            <h4
                                className="security__see-document"
                                onClick={() =>
                                window.open(
                                    '/documents/lg_333_2003.pdf',
                                    '_blank'
                                )}>
                            LEGE nr. 333 din 8 iulie 2003 privind paza obiectivelor, bunurilor, valorilor si protectia persoanelor
                            </h4>
                        </div>
                        </div>
                        </div>
                        <img src='/images/law-img.jpg' className='standard__only-desktop security__analysis-img' alt='law'/>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default Legislation;