import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LazyLoad from 'react-lazy-load';
import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';

class SecuritySystems extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Sisteme de control acces</h2>
                    <p className='security__top-info security__acces-top-info'>In prezent, sistemele de control al accesului trebuie sa presupuna mai mult decat
                        inchiderea sau deschiderea usilor. Afacerea dumneavoastra are nevoie de un sistem de
                        securitate care sa va permita controlul si procesarea eficienta a informatiilor. Sistemele de
                        management ale accesului furnizate de Covo Solutions, va asigura ca doar persoanele
                        autorizate au acces. Siguranta sporita precum si gestiunea accesului caracteristice
                        sistemelor electronice pot fi utile in aceeasi masura oricarei afaceri indiferent de
                        anvergura. Controlul accesului este important in aceasi masura atat profitului cat si
                        sigurantei dumneavoastra.</p>
                    <p className='security__info'>Dispozitivele de colectare a datelor de pontaj și prezență, gestionează costul
                        forței de muncă și  asigură angajatorul că politicile de salarizare corporative sunt
                        îndeplinite.</p>
                                            <p className='security__info'>În plus, riscul de lipsă de conformitate este redus prin imposibilitatea
                        angajaților de a se ponta,  înainte sau după program.</p>
                        <p className='security__info'>Productivitatea forței de muncă crește, deoarece angajații nu mai au posibilitatea
                        de a se ponta în perioada în care nu s-au prezentat la locul de muncă.</p>
                        <LazyLoad
                          debounce={false}
                          offsetVertical={500}
                        >
                          <OptimizedImg src='/images/access-card.png' imageUrlWebp='/images/access-card.webp' className='security__section-img security__acces-img' alt='security' />
                        </LazyLoad>
                        <p className='security__info'>Vă recomandăm instalarea unui sistem de pontaj automat din următoarele
                        motive:</p>
                        <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Se reduc costurile salariale prin controlul strict al orelor suplimentare;</li>
                                <li className='security__list-type'>Se exploatează 100% programul de lucru;</li>
                                <li className='security__list-type'>Oferă o viziune de ansamblu asupra angajaților, ajutând la evaluarea
                                productivității acestora;</li>
                                <li className='security__list-type'>Este posibilă introducerea unui program flexibil.</li>
                            </ul>
                            <p className='security__info'>Elementul central al sistemul de pontaj îl reprezintă serverul de stocare a
                                evenimentelor, server ce poate fi accesat local sau de la distanță printr-o
                                interfață web. De asemenea este posibilă gestionarea sistemului de pontaj prin
                                dispozitive portabile bazate pe Android, iOS, Windows Mobile, etc.</p>
                                <h3 className='security__subtitle'>Din punct de vedere al dispozitivelor de intrare a datelor avem:</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Cititoarele de carduri  RFID( radio-frequency identification)</li>
                                <li className='security__list-type'>Cititoarele de amprentă</li>
                                <li className='security__list-type'>Cititoarele cu funcție de recunoaștere facială</li>
                            </ul>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Avantajele sistemelor de supraveghere si control al accesului:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>identifica accesul in organizatia dumneavoastra</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>definire rapoarte de pontaj</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>restrictioneaza accesul in zonele protejate</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>asigura un mediu de munca mai sigur</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>gestioneaza si controleaza accesul la pana la mii de puncte ale unei suprafete</li>
                                </div>
                            </ul>
                            <h3 className='security__subtitle'>Solutii control acces:</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>solutii web, multi-site;</li>
                                <li className='security__list-type'>solutii mici si mijlocii PC;</li>
                                <li className='security__list-type'>solutii enterprise;</li>
                                <li className='security__list-type'>carduri si cititoare;</li>
                                <li className='security__list-type'>sisteme de legitimare bazate pe identificare foto;</li>
                                <li className='security__list-type'>sisteme biometrice.</li>
                            </ul>
                        </div>
                        <LazyLoad
                          debounce={false}
                          offsetVertical={500}
                        >
                          <OptimizedImg src='/images/door.jpg' imageUrlWebp='/images/door.webp' className='standard__only-desktop security__section-img security__accces-door-img security__access-control-img' alt='door' />
                        </LazyLoad>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default SecuritySystems;
