import axios from 'axios';

export const contactService = {
    saveContact
  };

  function saveContact(contact) {
    let url = `${process.env.REACT_APP_BLOG_API_URL}/api/contacts`;

    const data = {
      contact: {
        email: contact.email,
        name: contact.name,
        phone: contact.phoneNumber,
        message: contact.message
      }
    };

    const requestOptions = {
      headers: {
        Accept: 'application/json',
        'Content-type': 'application/json'
      }
    };

    return axios.post(url, data, requestOptions);
  }

  function handleResponse(response) {
    if (response.status === 401) {
      const error = response.statusText;

      return Promise.reject(error);
    }

    return response.json().then(data => {
      if (!response.ok) {
        const error = (data && data.error) || response.statusText;
        return Promise.reject(error);
      }

      return data;
    });
  }
