import React from 'react';
import './SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class SecuritySystems extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Sisteme de supraveghere</h2>
                    <p className='security__top-info'>Solutiile de supraveghere video joaca un rol important in lupta impotriva activitatilor ilegale si contribuie si la cresterea
                    operativitatii tuturor sistemelor de securitate instalate in obiectivele cu cerinte minimale de securitate.</p>
                    <p className='security__info'>Solutiile oferite sunt <span className='security__bold'>conform normelor legislative</span> iar echipamentele pe care le avem de la producatori renumiti, ne permit sa oferim solutii personalizate si adecvate modului de functionare a fiecarui obiectiv. Covo Solutions ofera tot ce este
                    necesar pentru <span className='security__bold'> proiectarea, instalarea si mentenanta </span> sistemelor de monitorizare video cu circuit inchis. Prioritatea
                    noastra este asigurarea functionalitatii optime a produselor si serviciilor furnizate.</p>
                    <p className='security__info'>Un alt beneficiu major este acela că vor crea un climat de securitate în zona în care sunt instalate,
                    rezultat ce duce la o descurajare sigură a faptelor antisociale sau, în cazul defavorabil, la identificarea
                    ușoara a faptașilor.</p>
                    <p className='security__info'>Sistemele de supraveghere video va ofera echipamentul de care aveti nevoie pentru a va
                    putea desfasura activitatea si a va proteja afacerea. Echipamentele CCTV va ajuta la
                    combaterea infractiunilor, furnizandu-va in acelasi timp informatiile necesare procesului
                    decizional. Covo Solutions va ofera o gama larga de tehnologie video digitala avansata, care
                    va permite organizarea si controlul rezultatelor obtinute, precum si accesul la sistemul
                    central de monitorizare sau la sistemul din alte locatii.</p>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Avantajele sistemelor de supraveghere video:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>combate infractiunile</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>contribuie la protectia angajatilor si a clientilor</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>permite vizionarea in direct sau inregistrata a evenimentelor</li>
                                </div>
                            </ul>
                            <h3 className='security__subtitle'>Sisteme de supraveghere video:</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>camere supraveghere fixe</li>
                                <li className='security__list-type'>camere supraveghere mobile de tip Speed Dome</li>
                                <li className='security__list-type'>camere mobile tip rail</li>
                                <li className='security__list-type'>camere pentru aplicatii speciale, AntiEX, LPR, termoviziune</li>
                                <li className='security__list-type'>ultimele tehnologii video IP, AHD, TVI</li>
                                <li className='security__list-type'>inregistratoare Video – DVR, NVR</li>
                                <li className='security__list-type'>sisteme de supraveghere, gestionare si monitorizare la distanta</li>
                            </ul>
                            <h3 className='security__subtitle'>Echipamentele instalate de noi permit:</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Monitorizarea de la distantă prin PC sau smartphone/tabletă;</li>
                                <li className='security__list-type'>Detectarea mișcării într-un anumit perimetru și alertarea pazei;</li>
                                <li className='security__list-type'>Vizualizarea înregistrărilor local, folosind un PC sau chiar cu ajutorul telefonului mobil cu imaginii de rezoluții FULL HD;</li>
                                <li className='security__list-type'>Zoomarea imaginilor pentru a avea o detaliere mai clară a punctului de interes;</li>
                                <li className='security__list-type'>Conectarea sistemului video cu cel de alarmă pentru a primi imagini în timp real la activarea sirenei;</li>
                                <li className='security__list-type'>Identificarea obiectelor lipsă sau a celor uitate într-un anumit perimetru;</li>
                                <li className='security__list-type'>Înregistrarea într-o bază de date a numerelor autovehiculelor ce trec printr-un anumit punct;</li>
                                <li className='security__list-type'> Capturi de imagini în timp real.</li>
                            </ul>
                        </div>
                        <img src='/images/covo-1.jpg' className='standard__only-desktop security__section-img' alt='security'/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default SecuritySystems;