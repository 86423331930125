import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import LazyLoad from 'react-lazy-load';
import OptimizedImg from '../../components/OptimizedImg/OptimizedImg';

class EletricInstalations extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper security__flex-wrapper'>
                    <div>
                    <h2 className='security__title'>Servicii de instalatii electrice</h2>
                    <p className='security__top-info'>Instalatiile de curenti slabi sunt acele instalatii electrice care transmit curent electric de intensitate mica, spre deosebire de instalatiile obisnuite, precum instalatiile de iluminat si prize. Acestea pot fi sisteme de cabluri de retele de telefonie si fibre optice, cablare structurala, detectie si avertizare, instalatii pentru prevenirea incendiului, instalatii de acces, televiziuni cu circuit inchis, retele de calculatoare, instalatii pentru semnalizari acustice si optice, instalatii electrofonice, instalatii de ceasuri electrice.</p>
                    <p className='security__info'>COVO SOLUTIONS  executa orice fel de instalatii electrice de curenti slabi, pentru cladiri rezidentiale, civile, pentru cladiri economice, dar si pentru cladiri industriale. Indiferent de amplitudinea si complexitatea proiectului, echipa de experti este pregatita sa rezolve orice problema de ordin electric si nu numai din acest domeniu. COVO SOLUTIONS poate realiza si instalatii sanitare, instalatii de protectie contra incendiilor, instalatii termice si instalatii de climatizare.</p>
                    <p className='security__info'>Orice cladire si orice locatie are nevoie de servicii specializate in ceea ce priveste instalatiile de orice fel, pentru ca are necesitati speciale si caracteristici unice. Personalizarea serviciilor este un aspect important, de care eficienta instalatiilor depinde intr-o masura considerabila. De aceea, echipa COVO SOLUTIONS este pregatita sa rezolve cu succes orice proiect si sa se adapteze la nevoile speciale ale oricarei cladiri, pentru a asigura o functionalitate ridicata si o eficienta pe termen lung.</p>
                    <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Detectie si semnalizare incendiu;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Limitare si stingere incendiu.</li>
                                </div>
                            </ul>

                    </div>
                    <LazyLoad
                      debounce={false}
                      offsetVertical={500}
                    >
                      <OptimizedImg src='/images/electricity-service.jpg' imageUrlWebp='/images/electricity-service.webp' className='standard__only-desktop security__section-img security__accces-door-img security__access-control-img' alt='door' />
                    </LazyLoad>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default EletricInstalations;
