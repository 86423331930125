import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class VoiceDateWeb extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'> Rețele voce-date</h2>
                    <p className='security__top-info'>Sistemele de voce-date sunt instalatii complexe, avand in componenta infrastructuri de cabluri (UTP, FTP, fibra optica, cabluri de telefonie, etc.), echipamente active (servere, centrale telefonice, switchuri, routere, mediaconvertoare, etc.) si echipamente pentru posturile de lucru (prize voce-date, posturi telefonice, sisteme videoconferinta, PC-uri, tablete, smartphone-uri, smart-TV-uri, camere video, etc.).</p>
                    <h3 className='security__subtitle'>Solutii de voce - date:</h3>
                    <div >
                    <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Proiectare si realizare cablare structurata comuna voce-date;</li>
                                <li className='security__list-type'>Proiectare si realizare cablare structurata separata voce sau date;</li>
                                <li className='security__list-type'>Proiectare si realizare retele sau suduri de fibra optica;</li>
                                <li className='security__list-type'>Proiectare, instalare si echipare camere de comunicatii, echipamente rack.</li>
                                <li className='security__list-type'>Proiectare, realizare si configurare retele de voce, centrale telefonice, sisteme VoIP;</li>
                                <li className='security__list-type'>Proiectare si realizare sisteme de supraveghere video;</li>
                                <li className='security__list-type'>Proiectare si realizare sisteme de control-acces si de pontaj;</li>
                                <li className='security__list-type'>Reproiectare si refacere infrastructuri de voce-date sau infrastructuri de fibra optica deteriorate sau intr-o stare de degradare;</li>
                                <li className='security__list-type'>Reproiectare si refacere camere de comunicatii, echipamente rack.</li>
                            </ul>
                            <p className='security__info'>VoIP – Telefonia IP reprezinta procesul de transmitere a conversatiilor vocale prin interconectari sau prin retele de date in care este folosit acest protocol. Astfel, utilizatorii pot efectua apeluri telefonice catre orice telefon – software, mobil sau fix prin folosirea tehnologiei VoIP. In acest fel, fluxul audio este transmis prin internet si nu prin sistemul traditional. Cateva din caracteristicile de baza ale unei solutii VoIP sunt: identificarea apelantului, lista apelurilor in curs, directionarea apelurilor si apelul in asteptare.</p>
                    <p className='security__info'>NG-Network ofera solutii complete de cablare structurata pentru retele de voce-date, indiferent de complexitatea conexiunilor ce trebuie realizate sau de tipul de terminale utilizate.</p>
                    <p className='security__info'>Realizam retele de voce-date pentru cladiri industriale si de birouri, solutiile oferite de noi permitand obtinerea unei structuri fiabile si sigure, cu un grad mare de flexibilitate.</p>
                        <img src='/images/security-3.jpg' className='standard__only-desktop security__section-img' alt='security'/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default VoiceDateWeb;