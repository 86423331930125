import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class SecuritySystems extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper security__flex-wrapper'>
                    <div>
                    <h2 className='security__title'>Sisteme de alarma antiefractie</h2>
                    <p className='security__info'>Covo Solutions va ofera sisteme inovatoare care sa va protejeze impotriva intrarii prin efractie si a furtului. in plus, sistemele noastre sunt concepute astfel incat sa fie usor integrate in alte siteme de securitate.</p>
                    <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Clădiri rezidențiale, clădiri de birouri, unități administrative;</li>
                                <li className='security__list-type'>Spații comerciale, spați de comercializare bunuri și valori;</li>
                                <li className='security__list-type'>Depozite și unități de producție;</li>
                                <li className='security__list-type'>Case de amanet și schimb valutar;</li>
                                <li className='security__list-type'>Alte obiective ce necesită a fi securizate.</li>
                            </ul>
                            <h3 className='security__subtitle'>Comercializăm următoarele categorii de sisteme de alarma, in funcție de proiect:</h3>
                            <ul className='security__list security__list-types'>
                            <li className='security__list-type'>Sisteme ce folosesc numai senzori cablați;</li>
                            <li className='security__list-type'>Sisteme ce folosesc numai senzori wireless;</li>
                                <li className='security__list-type'>Sisteme hibride ce le înglobează pe ambele categorii.</li>
                                </ul>
                    <div className='security__lower-section'>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Avantajele sistemelor de alarma antiefractie:</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>ajuta la protejarea afacerii dumneavoastra impotriva infractiunilor;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>poate include optiuni precum butoane de panica, senzori de miscare,
                                    protectie perimetrala, fapt care confera angajatilor confort si liniste;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>ajuta la identificarea conditiilor critice precum temperaturi foarte mari sau
                                foarte mici</li>
                                </div>
                            </ul>
                            <h3 className='security__subtitle'>Funcțiile cele mai importante ale unui sistem de alarmă sunt:</h3>
                            <ul className='security__list security__list-types'>
                            <li className='security__list-type'>Notificare pentru toate categoriile de  evenimente:</li>
                            <h4 className='security__list-type security__sublist-item'>Declanșarea sirenei;</h4>
                            <h4 className='security__list-type security__sublist-item'>Erori în sistem: lipsă oră, baterie descărcată, deconectare sirenă, etc.;</h4>
                            <h4 className='security__list-type security__sublist-item'>Căderea și revenirea tensiunii de alimentarea  220V.</h4>
                            <li className='security__list-type'>Realizarea funcției de armare/dezarmare  prin cod, telecomandă, aplicație Andriod/Ios, SMS sau prin apelarea centralei și interacționarea cu un robot telefonic;</li>
                                <li className='security__list-type'>Posibilitatea de a arma sistemul pe timpul nopții, pentru o siguranță sporită;</li>
                                <li className='security__list-type'>Armarea separată și/sau automată pe partiții și zone funcționale;</li>
                                <li className='security__list-type'>Crearea de diferite scenarii privind modul în care  sezorii PIR vor declanșa alarma;</li>
                                <li className='security__list-type'>Evenimentele primite de la senzori vor fi trimise prin e-mail sau sms către beneficiar sau direct spre dispecerat</li>
                                </ul>
                            <h3 className='security__subtitle'>  Putem concepe scenarii inteligente pentru controlul locuinței prin intermediul sistemului de alarmă;</h3>
                            <ul className='security__list security__list-types'>
                            <li className='security__list-type'>Controlul centralei termice de la distanță;</li>
                            <li className='security__list-type'>Activarea automată a iluminatului cu sirena și controlul acestuia de la distanță;</li>
                                <li className='security__list-type'>Putem realiza scenarii de lumină, pe anumite zone, pentru a simula prezența beneficiarului în imobil controlate local sau remote;</li>
                                <li className='security__list-type'>La activarea alarmei primiți prin e-mail imagini de la camerele video;</li>
                                <li className='security__list-type'>Înrolăm telecomanda sistemului de alarmă cu motorul de la poartă, de la ușa de garaj, jaluzele, etc.</li>
                                </ul>
                        </div>

                    </div>
                    </div>
                    <img src='/images/alarm.jpg' className='standard__only-desktop security__section-img security__alarm-img' alt='security'/>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default SecuritySystems;
