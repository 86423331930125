import React from 'react';
import './Footer.scss';
import '../../resources/main.scss';
import {Link} from 'react-router-dom';

class Footer extends React.Component{
    scrollToTop=()=>{
        window.scrollTo(0, 0);
    }
    render(){
        return(
            <div className='standard footer'>
                <ul className='footer__list'>
                   <Link className='standard__link' to='/sisteme-de-securitate'onClick={this.scrollToTop}> <li>Sisteme de securitate</li></Link>
                   <Link className='standard__link' to='/servicii-it'onClick={this.scrollToTop}> <li>Servicii IT</li></Link>
                   <Link className='standard__link' to='/analiza-risc' onClick={this.scrollToTop}> <li>Analiza de risc</li></Link>
                   <Link className='standard__link' to='/certificari' onClick={this.scrollToTop}> <li>Certificari</li></Link>
                   <Link className='standard__link' to='/legislatie'onClick={this.scrollToTop}> <li>Legislatie</li></Link>
                   <Link className='standard__link' to='/contact'onClick={this.scrollToTop}> <li>Contact</li></Link>
                </ul>
                <div className='footer__sub-info'>
                    <div className='footer__contact'>
                        <h5 className='footer__details'>contact@covosolutions.ro</h5>
                        <h5 className='footer__details'>0755 686 871</h5>
                    </div>
                    <Link to='/' className='standard__link'><img src='/images/facebook.svg' className='footer__facebook' alt='facebook'/></Link>
                    <a className='standard__link'rel="noopener noreferrer" href='https://prometeus-tech.com/' target='_blank'><h5 className='footer__details'>©2021 PrometeusTech. All rights reserved.</h5></a>
                </div>
            </div>
        )
    }
}

export default Footer;