import axios from 'axios';

export const blogService={
    getArticles,
    getArticleBySlug
}

function getArticles(){
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json'
        }
      };

      let url = `${process.env.REACT_APP_BLOG_API_URL}/api/articles`;


     return axios.get(url, requestOptionsPost);
}

function getArticleBySlug(slug) {
    const requestOptionsPost = {
        headers: {
          Accept: 'application/json'
        }
      };

      let url = `${process.env.REACT_APP_BLOG_API_URL}/api/articles/get_by_slug/${slug}`;


     return axios.get(url, requestOptionsPost);
}
