import React from 'react';
import '../SecuritySystems/SecuritySystems.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';

class ITMentenance extends React.Component{
    render(){
        return(
            <div className='standard security'>
                <Header/>
                <div className='security__wrapper'>
                    <h2 className='security__title'>Mentenanță IT</h2>

                    <div className='security__lower-section'>
                    <div>
                    <h3 className='security__subtitle'>Cum puteti sti daca afacerea dumneavoastra necesita externalizare IT?</h3>
                            <ul className='security__list security__list-types'>
                                <li className='security__list-type'>Din punct de vedere financiar, este mai eficienta externalizarea IT decat mentinerea unui angajat;</li>
                                <li className='security__list-type'>Activitatea companiei are de suferit din cauza lipsei de servicii IT constante;</li>
                                <li className='security__list-type'>Experienta presupune un grad mare de specializare, incat mentinerea unui angajat permanent fara experienta necesara, dar responsabil pentru aceasta functie nu se justifica.</li>
                            </ul>
                        <div className='security__list-section'>
                            <h3 className='security__subtitle'>Service IT - Servicii incluse</h3>
                            <ul className='security__list'>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Monitorizarea echipamentelor IT, atat hardware cat și software;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Interventii la solicitare pentru remedierea defectiunilor;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Helpdesk pentru angajati;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Punerea în funcțiune a echipamentelor IT;</li>
                                </div>
                                <div className='security__list-item-box'>
                                    <img src='/images/check.svg' alt='check' className='security__list-img'/>
                                    <li className='security__list-item'>Implementare de noi soluții.</li>
                                </div>
                            </ul>
                        </div>
                        </div>
                        <img src='/images/laptop.jpg' className='standard__only-desktop security__analysis-img' alt='security'/>
                    </div>
                </div>
                <Footer/>
            </div>
        )
    }
}

export default ITMentenance;
