import React from 'react';
import { Link } from 'react-router-dom';
import './SubMenu.scss';

class SubMenu extends React.Component{
    render(){
        return(
            <div className={'submenu__wrapper '+ (this.props.buttonHovered === this.props.menu.id ? 'active' : '')}>
              <ul className='submenu__links'>
                {this.props.menu.submenu && this.props.menu.submenu.map(subMenuItem => (
                    <li key={subMenuItem.id}className="submenu__title">
                        <Link
                        className='standard__link submenu__link'
                        to={subMenuItem.url}
                        onClick={() => this.props.closeMenu()}>
                        <span className='submenu__link-item'> {subMenuItem.item} </span>
                        </Link>
                </li>
                ))}
             </ul>
            </div>
        )
    }
}

export default SubMenu;