import React from 'react';
import './Article.scss';
import '../../resources/main.scss';
import Header from '../../components/Header/Header';
import Footer from '../../components/Footer/Footer';
import {blogService} from '../../services/BlogService';

class Article extends React.Component{
    constructor(props){
        super(props)
        this.state={
            article: {}
        }
    }

    componentDidMount() {
      if (this.props.match.params.id) {
        blogService.getArticleBySlug(this.props.match.params.id).then(response=>{
            let article = { ...this.state.article };
            article = response.data;
            this.setState({ article });
        })
        .catch(err=>console.log(err))
      } else {
        this.props.history.push('/noutati');
      }
    }

    render(){
        return(
            <div className='standard article blog'>
                <div className='blog__header'>
                   <Header/>
                </div>
                <div className='article__container'>
                    <h1 className='article__title'>
                      {this.state.article.title}
                    </h1>
                    { this.state.article.main_image && this.state.article.main_image.url ? (
                      <img src={process.env.REACT_APP_BLOG_API_URL + this.state.article.main_image.url} className='article__main-img' alt='article main'/>
                    ) : (
                      <img src="/images/image-gym.jpg" className='article__main-img' alt='article main'/>
                    )}
                    <div className='article__info-wrapper'>
                        <p className='article__paragraf article__first-paragraph'>{this.state.article.description}</p>

                        { this.state.article.article_sections && this.state.article.article_sections.map((article_section, index) => (
                            article_section.tip !== 'image' ? (
                              React.createElement(
                                article_section.fe_component,
                                { className: `article__${article_section.tip}`, key: article_section.id },
                                article_section.value
                              )
                            ) : (
                              React.createElement(
                                article_section.fe_component,
                                { className: `article__${article_section.tip}`,
                                  key: article_section.id,
                                  src: article_section.image && article_section.image.url ? `${process.env.REACT_APP_BLOG_API_URL}${article_section.image.url}` : '/images/image-gym.jpg'
                                },
                                null
                              )
                            )

                        ))}
                    </div>

                </div>
                <Footer/>
            </div>
        )
    }
}

export default Article;
